@import '~antd/dist/antd.css';

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.main-content { 
    padding: 24px; 
    background: #FFF;
    margin-bottom: 30px;
    min-height: 50vh;
}

.tm-flex-row {
    display: flex;
    flex-flow: row nowrap;
}
.tm-flex-row.ai-center {
    align-items: center;
}

.logo {    
    height: 25px;
    width: 180px;
    background-image: url(https://i1.wp.com/trimark.co.nz/wp-content/uploads/2020/05/Trimark_gamecube.png?resize=231%2C25&ssl=1);
    background-position: 50% 50%;
}

.selector-container {
    display: flex;
    flex-flow: row nowrap;
}
.selector-container > div {
    margin-right: 20px;
}

.map-container {
    margin: 30px 0;
    border: 1px solid #DDD;
    min-height: 250px;
    background: #eee;
}